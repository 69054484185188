import {
    LeaveOfAbsenceResource,
    LeaveOfAbsence,
    LeaveOfAbsencePostRequest,
    LeaveOfAbsenceForm,
} from './LeaveOfAbsences';
import { transformToPeriod } from '../Period/PeriodTransformers';
import { formatDate } from '../../helpers/date';
import { DateFormat } from '../../types/dateFormatTypes';

export const transformLeaveOfAbsences = (resource: LeaveOfAbsenceResource): LeaveOfAbsence => ({
    id: resource.id,
    createdAt: new Date(resource.attributes.createdAt),
    status: resource.attributes.status,
    declineReason: resource.attributes.declineReason,
    reason: resource.attributes.reason || '',
    period: transformToPeriod(new Date(resource.attributes.start), new Date(resource.attributes.end)),
});

export const transformLeaveOfAbsencesDetailToFormDataApiParams = (formData: LeaveOfAbsenceForm): LeaveOfAbsencePostRequest => ({
    type: 'leaveOfAbsences',
    attributes: {
        end: formatDate(formData.endDate, DateFormat.apiDateTime),
        start: formatDate(formData.startDate, DateFormat.apiDateTime),
        reason: formData.reason,
    },
});
