import moment from 'moment';

// eslint-disable-next-line import/no-cycle
import { getLocaleSpecification } from './date/DateTimeParser';
import { differenceInSeconds } from '../@paco/helpers/date';

function getMomentDate(date) {
    // Remove timezone from date
    const offset = moment.parseZone(date).utcOffset();
    return moment.utc(date).add(offset, 'minutes');
}

/**
 * Convert a datetime to a time like 18:00.
 * @param date
 * @returns {string}
 */
export function convertDateToTime(date) {
    return getMomentDate(date).format('HH:mm');
}

/**
 * Convert a date to a day string like 'Monday'.
 * @param date
 * @returns {string}
 */
export function convertDateToDay(date) {
    return getMomentDate(date).format('dddd');
}

/**
 * Convert a date to a date string like (21 July).
 * @param date
 * @returns {string}
 */
export function convertDateToDateString(date) {
    return getMomentDate(date).format('D MMMM');
}

/**
 * Convert a date to a short date string like (21 aug).
 * @param date
 * @returns {string}
 */
export function convertDateToShortDateString(date) {
    const parsedDate = getMomentDate(date);
    const localeSpecification = getLocaleSpecification();
    const day = parsedDate.date();
    const month = parsedDate.month();
    const { monthsShort } = localeSpecification;

    return `${day} ${monthsShort[month]}`;
}

/**
 * Convert a date to a date string like (21 Mar. 2019).
 * @param date
 * @returns {string}
 */
export function convertDateToDateYearString(date) {
    return getMomentDate(date).format('D MMM. Y');
}

/**
 * Convert a date to a date string like (21 March 2019).
 * @param date
 * @returns {string}
 */
export function convertDateToDateYearCompleteString(date) {
    return getMomentDate(date).format('D MMMM Y');
}

/**
 * Convert a date to a date time string like (21 Aug 2019 10:00:00).
 * @param date
 * @returns {string}
 */
export function convertDateToDateTimeString(date) {
    return getMomentDate(date).format('D MMM Y HH:mm');
}

/**
 * Convert a date to a month day like (25).
 * @param date
 * @returns {string}
 */
export function convertDateToMonthDay(date) {
    return getMomentDate(date).format('D');
}

/**
 * Convert a date to a month day like (25).
 * @param date
 * @returns {string}
 */
export function convertDateToMonth(date) {
    return getMomentDate(date).format('MMMM');
}

/**
 * Converts a moment object to the Year-Month-Day format.
 *
 * @param date
 * @returns {string}
 */
export function formatDate(date) {
    return `${getMomentDate(date).format('YYYY-MM-DD')}`;
}

const prettifySegment = unit => (`${unit}`.length > 1 ? unit : `0${unit}`);

export const getTimeSegments = (startTime, endTime = new Date()) => {
    const totalSeconds = differenceInSeconds(endTime, startTime);

    const h = Math.floor(totalSeconds / 3600);
    const m = Math.floor((totalSeconds % 3600) / 60);
    const s = Math.floor((totalSeconds % 3600) % 60);

    return {
        h: prettifySegment(h),
        m: prettifySegment(m),
        s: prettifySegment(s),
    };
};

export function getWeekDays(weekStart) {
    const days = [weekStart];
    for (let i = 0; i < 7; i += 1) {
        days.push(
            moment(weekStart)
                .add(i, 'days')
                .toDate(),
        );
    }
    return days;
}

export function getWeekRange(date) {
    return {
        from: moment(date)
            .isoWeekday(1)
            .toDate(),
        to: moment(date)
            .isoWeekday(7)
            .toDate(),
    };
}

export const getDifferenceInTime = (startTime, endTime = new Date(), withMinutes = false) => {
    const segments = getTimeSegments(startTime, endTime);

    return `${segments.h}:${segments.m}${withMinutes ? `:${segments.s}` : ''}`;
};
