import { addDays, differenceInDays, formatDate } from '../../helpers/date'
import { DateFormat } from '../../types/dateFormatTypes'
import { translate } from '../../../helpers/translations/translator'
import { convertDateToDateYearString } from '../../../helpers/DateTimeParser'

export const getLeaveOfAbsencesDateLabel = (start: Date, end?: Date): string => {
    const formattedStartDate = convertDateToDateYearString(start);

    if (!end) {
        return `${translate('pages.absences.from')} ${formattedStartDate}`;
    }

    const daysBetween = differenceInDays(end, start);

    if (daysBetween === 0) {
        return formattedStartDate;
    }

    const formattedEndDate = convertDateToDateYearString(addDays(start, daysBetween));

    return `${formattedStartDate} ${translate('pages.absences.until')} ${formattedEndDate}`;
};

export const getLeaveOfAbsencesDateLabelShort = (date?: Date): string => {
    return formatDate(date!, DateFormat.dateWithStandAloneMonthShort);
};
